import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { cloneElement } from 'react';
import { RootState } from '../../app/store';

export interface CommandLineInterfaceComponent extends JSX.Element {
    onComplete?: () => void;
}

export interface CommandLineInterfaceState {
    elements: CommandLineInterfaceComponent[];
    waitingQueue: JSX.Element[];
    status: 'idle' | 'waitingToComplete' | 'failed';
    addElementTrigger: NodeJS.Timeout;
}

const initialState: CommandLineInterfaceState = {
    elements: [],
    waitingQueue: [],
    status: 'idle',
    addElementTrigger: setTimeout(() => {}, 0)
};

const nextElement = createAsyncThunk(
    'commandLineInterface/nextElement',
    (data: undefined, thunkAPI) => {
        const currentState: CommandLineInterfaceState = (thunkAPI.getState() as any).commandLineInterface;
    
        if (currentState.status === 'idle') {
            return cloneElement(currentState.waitingQueue[0], {
                key: currentState.elements.length,
                onComplete: (...args: any[]) => {
                    const dispatch = thunkAPI.dispatch;
                    dispatch(changeStatus('idle'));
                    if (currentState.waitingQueue[0].props.onComplete) {
                        currentState.waitingQueue[0].props.onComplete(...args);
                    }
    
                    if (currentState.waitingQueue.length > 1) {
                        dispatch(nextElement());
                    }
                }
            });
        }

        return undefined;

});

export const commandLineInterfaceSlice = createSlice({
    name: 'commandLineInterface',
    initialState,
    reducers: {
        changeStatus(state, action) {
            state.status = action.payload;
        },
        addElement(state, action) {
            clearTimeout(state.addElementTrigger);
            const dispatch = action.payload.dispatch;
            const element = action.payload.element;

            const newElement = cloneElement(action.payload.element, {
                key: state.elements.length,
                onComplete: (...args: any[]) => {
                    if (element.props.onComplete) {
                        element.props.onComplete(...args);
                    }
                }
            });

            state.waitingQueue.push(newElement);

            state.addElementTrigger = setTimeout(() => {
                dispatch(nextElement());
            }, 800);
        },
        clearAll(state) {
            state.elements = [];
        }
    },
    extraReducers: (builder) => {
        builder.addCase(nextElement.fulfilled, (state, action) => {
            if (action.payload) {
                state.elements.push(action.payload);
                state.waitingQueue = state.waitingQueue.splice(1, state.waitingQueue.length - 1);
                state.status = 'waitingToComplete';
            }
        });
        // builder.addCase(addElement.fulfilled, (state, action) => {
        //     if (action.payload) {
        //         state.elements.push(action.payload);
        //     }
        // });
    }
});

const changeStatus = commandLineInterfaceSlice.actions.changeStatus;

export const { clearAll, addElement } = commandLineInterfaceSlice.actions;

export const selectCommandLineInterfaceValue = (state: RootState) => state.commandLineInterface.elements;
export default commandLineInterfaceSlice.reducer;
