import { useState, KeyboardEvent, ChangeEvent, useEffect } from "react"

export interface TypingLabelProps {
    text: string,
    alignLeft?: boolean,
    onComplete?: () => void,
    noTyping?: boolean,
};

export default function TypingLabel (props: TypingLabelProps) {
    const [labelTyping, setLabelTyping] = useState('');
    const [className, setClassName] = useState('text-l font-bold flex-none');

    useEffect(() => {
    
        if (props.noTyping) {
            setLabelTyping(props.text);
            if (props.onComplete) {
                setTimeout(() => {
                    props.onComplete!();
                }, 100);
            }
            return;
        } else {
            const interval = setInterval(() => {
                if (labelTyping.length === props.text.length) {
                    if (props.onComplete) {
                        props.onComplete();
                    }
                    clearInterval(interval);
                    return;
                }

                setLabelTyping(labelTyping + props.text[labelTyping.length]);
            }, 10);

            return () => clearInterval(interval);
        }
    });

    useEffect(() => {
        if (props.alignLeft) {
            setClassName('text-l px-4 font-bold flex-none text-left');
        }
    }, [props.alignLeft]);
    
    return (
        <div className={className + ' py-1'}>{labelTyping}</div>
    )
}