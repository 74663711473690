import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../../app/hooks";
import FieldInput from "../../../components/FieldInput";
import TypingLabel from "../../../components/TypingLabel";
import { addElement, clearAll } from "../commandLineInterfaceSlice";

export default function LoginCommands() {
    const dispatch = useAppDispatch();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [sendEmail, setSendEmail] = useState(false);

    function dispatchLogin() {
        dispatch(addElement({element: <TypingLabel text="Authentication Required..." />, dispatch}));

        dispatch(addElement({
            element: <FieldInput
                    label="Enter Email"
                    setExtraValue={setEmail} 
            />,
            dispatch
            }
        ));

        dispatch(addElement({
            element: <FieldInput 
                label="Enter Password"
                setExtraValue={setPassword}
                type='password' 
                onComplete={() => setSendEmail(true)} 
            />,
            dispatch
        }));
    }

    function sendLoginRequest(loginEmail: string, loginPassword: string) {
        signInWithEmailAndPassword(getAuth(), loginEmail, loginPassword)
            .then(() => dispatch(clearAll()))
            .catch((e) => {
                setSendEmail(false);
                dispatch(addElement({ element: <TypingLabel text="Authentication Failed..." />, dispatch }));
                dispatch(addElement({element: <TypingLabel text="Invalid email or password..." /> , dispatch}));
                dispatchLogin();
            });
    }

    useEffect(() => {
        let init = setTimeout(() => {
            dispatchLogin();
        }, 500);

        return () => {
            clearTimeout(init);
        };
    }, []);

    useEffect(() => {
        if (sendEmail) {
            sendLoginRequest(email, password);
        }
    }, [sendEmail]);

    return (
        <div></div>
    );
}