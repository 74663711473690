import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectCommandLineInterfaceValue, addElement, clearAll } from "./commandLineInterfaceSlice";
import TypingLabel from "../../components/TypingLabel";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import LoginCommands from "./commands/LoginCommands";
import Loading from "../../components/Loading";
import AdventureCommands from "./commands/AdventureCommands";
import axios from "axios";
import { SERVER_URL } from "../../constants";

export default function CommandLineInterface() {
    const elements = useAppSelector(selectCommandLineInterfaceValue);
    const dispatch = useAppDispatch();
    const [currentCommand, setCurrentCommand] = useState<JSX.Element | undefined>();
    const [ready, setReady] = useState(false);
    const [clearCommands, setClearCommands] = useState(false);


    useEffect(() => {
        dispatch(clearAll());
        let init = setTimeout(async () => {
            dispatch(addElement({
                element: <TypingLabel text="Checking state please wait..." />,
                dispatch,
            }));

            dispatch(addElement({
                element: <TypingLabel text="Checking authentication..." />,
                dispatch,
            }));

            onAuthStateChanged(getAuth(), async (user) => {
                setReady(false);
                if (user) {
                    try {
                        await axios.post(`${SERVER_URL}/api/v1/auth`, {}, {
                            headers: {
                                'Authorization': `Bearer ${await user.getIdToken()}`
                            },
                            withCredentials: true
                        });

                        dispatch(addElement({
                            element: <TypingLabel 
                                text="Authentication pass!"
                            />,
                            dispatch,
                        }));

                        setCurrentCommand(<AdventureCommands user={user} />);
                    } catch (err) {
                        dispatch(addElement({
                            element: <TypingLabel 
                                text="Failed to authenticate..."
                            />,
                            dispatch,
                        }));
                        setCurrentCommand(<LoginCommands />);
                    }
                } else {
                    setCurrentCommand(<LoginCommands />);
                }

                setReady(true);
            });
        }, 500);
        
        return () => {
            clearTimeout(init);
        };
    }, []);
    
    return (
        <div className="text-left px-4 py-2">
            {elements}
            {currentCommand}
        </div>
    )
}