import { User } from "firebase/auth";
import { useEffect } from "react";
import { useAppDispatch } from "../../../app/hooks";
import FieldInput from "../../../components/FieldInput";
import TypingLabel from "../../../components/TypingLabel";
import { SERVER_WEBSOCKET_URL } from "../../../constants";
import { addElement } from "../commandLineInterfaceSlice";

export default function AdventureCommands(props: {user: User}) {
    const dispatch = useAppDispatch();
    
    useEffect(() => {
        let init = setTimeout(async () => {
            dispatch(addElement({
                element: <TypingLabel text="Connecting..." />,
                dispatch
            }));

            const ws = new WebSocket(`${SERVER_WEBSOCKET_URL}/adventure`);

            ws.addEventListener("open", () => {
                dispatch(addElement({
                    element: <TypingLabel text="Connected!" />,
                    dispatch
                }));
            });

            ws.addEventListener("message", (event) => {
                const data = JSON.parse(event.data.toString());
                if (data.type === "text") {
                    dispatch(addElement({
                        element: <TypingLabel text={data.message} noTyping={data.noTyping} />,
                        dispatch
                    }));
                } else if (data.type === "command") {
                    dispatch(addElement({
                        element: <FieldInput
                            label={data.message}
                            onComplete={(value) => {
                                ws.send(value || '');
                            }} 
                        />,
                        dispatch
                    }));
                }
            });
            
        }, 1000);

        return () => {
            clearTimeout(init);
        };
    }, []);
    return (
        <div></div>
    )
}