import { useState, KeyboardEvent, ChangeEvent, useEffect, SetStateAction, Dispatch } from "react"
import TypingLabel from "./TypingLabel";

export interface FieldInputProps {
    label: string,
    onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void,
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void,
    type?: string,
    autoFocus?: boolean,
    onComplete?: (value?: string) => void,
    setExtraValue?: Dispatch<React.SetStateAction<string>>
};

export default function FieldInput (props: FieldInputProps) {
    const [value, setValue] = useState("");
    const [blockInput, setBlockInput] = useState(false);

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);

        if (props.setExtraValue) {
            props.setExtraValue(e.target.value);
        }

        if (props.onChange) {
            props.onChange(e);
        }
    }

    const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            setBlockInput(true);
        }

        if (props.onKeyDown) {
            props.onKeyDown(e);
        }
    }

    const [input, setInput] = useState<JSX.Element>(<input 
        type={props.type}
        className="p-2 animate-pulse::placeholde bg-black outline-none grow caret-input"
        onKeyDown={onKeyDown}
        onChange={onChange}
        autoFocus={props.autoFocus !== false}
    />);

    useEffect(() => {
        if (blockInput) {
            if (props.onComplete) {
                props.onComplete(value);
            }
            setInput(<div className="p-2 grow whitespace-normal text-left"><p>{props.type === 'password' ? value.replace(/./g, '*') : value}</p></div>);
        }
    }, [blockInput]);
    
    return (
        <div className="flex justify-start items-center">
            <TypingLabel text={props.label + " >"} />
            {input}
        </div>
    )
}